import { format } from 'date-fns/fp';
import {
  always,
  applySpec,
  constructN,
  defaultTo,
  ifElse,
  pipe,
  prop,
  propEq,
  propOr,
  propSatisfies,
  when,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

export const fromApiDisability = applySpec({
  disabilityFrom: pipe(
    prop('disabilityFrom'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  disabilityTo: pipe(
    prop('disabilityTo'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  hasEnded: propSatisfies(isNotNilOrEmpty, 'disabilityTo'),
  reportedIncident: propOr('DISEASE', 'reportedIncident'),
  levelOfDisability: propOr('FULL', 'levelOfDisability'),
  isInvalid: propSatisfies(isNotNilOrEmpty, 'levelOfDisability'),
  disabilityRentalFrom: pipe(
    prop('disabilityRentalFrom'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  undergoneSpecialistExamination: propOr(
    false,
    'undergoneSpecialistExamination'
  ),
  undergoneImagingExamination: propOr(false, 'undergoneImagingExamination'),
  undergoneSurgeryOrHospitalised: propOr(
    false,
    'undergoneSurgeryOrHospitalised'
  ),
  investigatedByPolice: propSatisfies(isNotNilOrEmpty, 'policeInvestigation'),
  policeInvestigation: propOr('', 'policeInvestigation'),
});

export const toApiDisability = applySpec({
  disabilityFrom: pipe(prop('disabilityFrom'), format('yyyy-MM-dd')),
  disabilityTo: ifElse(
    propEq('hasEnded', true),
    pipe(prop('disabilityTo'), format('yyyy-MM-dd')),
    always('')
  ),
  disabilityRentalFrom: ifElse(
    propEq('isInvalid', true),
    pipe(prop('disabilityRentalFrom'), format('yyyy-MM-dd')),
    always('')
  ),
  levelOfDisability: ifElse(
    propEq('isInvalid', true),
    prop('levelOfDisability'),
    always(undefined)
  ),
  policeInvestigation: ifElse(
    propEq('investigatedByPolice', true),
    prop('policeInvestigation'),
    always('')
  ),
  reportedIncident: prop('reportedIncident'),
  undergoneImagingExamination: prop('undergoneImagingExamination'),
  undergoneSpecialistExamination: prop('undergoneSpecialistExamination'),
  undergoneSurgeryOrHospitalised: prop('undergoneSurgeryOrHospitalised'),
});
