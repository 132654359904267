import { always, applySpec, curryN, pipe, prop, unary } from 'ramda';
import { EMPTY, forkJoin, merge } from 'rxjs';
import {
  map as rxMap,
  catchError,
  switchMap,
  take,
  defaultIfEmpty,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';

import {
  fromApiDisability,
  fromApiHospitalization,
  fromApiTakingCare,
  fromApiTempDisability,
  fromApiUnemployment,
  fromApiPersonalData,
  fromApiContactData,
  fromApiBankAccount,
} from '../../mappers';
import {
  FETCH_JOURNEY_STATE,
  fetchJourneyStateSuccess,
} from '../../actions/context.actions';

const getStateChunk = curryN(4, (ajax, activeJourneyId, mapper, fragment) =>
  ajax({
    url: `/gateway-server/journeys/${activeJourneyId}/${fragment}`,
    useAuth: true,
  }).pipe(
    rxMap(mapper),
    catchError(() => EMPTY)
  )
);

const hMapper = applySpec({
  riskType: always('H'),
  riskDetails: pipe(prop('response'), fromApiHospitalization),
});

const tpdMapper = applySpec({
  riskType: always('TPD'),
  riskDetails: pipe(prop('response'), fromApiDisability),
});

const ppdMapper = applySpec({
  riskType: always('PPD'),
  riskDetails: pipe(prop('response'), fromApiDisability),
});

const tdMapper = applySpec({
  riskType: always('TD'),
  riskDetails: pipe(prop('response'), fromApiTempDisability),
});

const tacMapper = applySpec({
  riskType: always('TAC'),
  riskDetails: pipe(prop('response'), fromApiTakingCare),
});

const iuMapper = applySpec({
  riskType: always('IU'),
  riskDetails: pipe(prop('response'), fromApiUnemployment),
});

const personalMapper = pipe(prop('response'), fromApiPersonalData);
const contactMapper = pipe(prop('response'), fromApiContactData);
const bankAccountMapper = pipe(prop('response'), fromApiBankAccount);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_JOURNEY_STATE),
    switchMap(({ payload }) =>
      forkJoin({
        riskType: merge(
          getStateChunk(
            ajax,
            payload.activeJourneyId,
            hMapper,
            'hospitalization'
          ),
          getStateChunk(
            ajax,
            payload.activeJourneyId,
            tpdMapper,
            'total-perm-disability'
          ),
          getStateChunk(
            ajax,
            payload.activeJourneyId,
            ppdMapper,
            'part-perm-disability'
          ),
          getStateChunk(
            ajax,
            payload.activeJourneyId,
            tdMapper,
            'temp-disability'
          ),
          getStateChunk(ajax, payload.activeJourneyId, tacMapper, 'care'),
          getStateChunk(ajax, payload.activeJourneyId, iuMapper, 'unemployment')
        ).pipe(take(1), defaultIfEmpty({ riskType: null, riskDetails: null })),
        personalData: getStateChunk(
          ajax,
          payload.activeJourneyId,
          personalMapper,
          'personal'
        ).pipe(defaultIfEmpty(null)),
        contactData: getStateChunk(
          ajax,
          payload.activeJourneyId,
          contactMapper,
          'address'
        ).pipe(defaultIfEmpty(null)),
        bankAccount: getStateChunk(
          ajax,
          payload.activeJourneyId,
          bankAccountMapper,
          'bank'
        ).pipe(defaultIfEmpty(null)),
      }).pipe(rxMap(unary(fetchJourneyStateSuccess)))
    )
  );
